import { useRef, useEffect, useState, useMemo } from 'react';
import imageCompression from 'browser-image-compression';
import { useNavigate } from 'react-router-dom';
import useEmblaCarousel from 'embla-carousel-react'; // hook
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';
import { useTranslation } from 'react-i18next';
import { convertImageFileToBase64 } from '../libs/image';
import BrowserModal from '../components/modal/BrowserModal';
import exifr from 'exifr';
import { isDesktop } from 'react-device-detect';
import '../styles/ImageLoader.css';
// 슬라이드 이미지 2
import cameraPng from '../assets/imgs/camera.png';
import galleryPng from '../assets/imgs/gallery.png';
// 하단 svg 이미지 2
import cameraIcon from '../assets/icons/img_photo.svg';
import galleryIcon from '../assets/icons/img_gallery.svg';
import flowerIcon from '../assets/icons/ic_flower.png';
// JS 함수
import { camera } from './app.android';

function ImageLoader() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
	const [selectedIndex, setSelectedIndex] = useState(0);
	const slideImg = [cameraPng, galleryPng];

	const slideText = ['카메라', '갤러리'];

	const [isUserDisplay, setIsUserDisplay] = useState<Boolean>(false);
	const setHeaderOptions = useSetRecoilState(headerOptionsState);
	const fileRef = useRef<HTMLInputElement | null>(null);

	const handleCameraCapture = async (imageData: string) => {
		setIsLoading(true);
		try {
			const exif = await exifr.parse(
				`data:image/jpeg;base64,${imageData}`,
			);
			console.log('EXIF Data:', JSON.stringify(exif, null, 2));

			localStorage.setItem(
				'blobImage',
				`data:image/jpeg;base64,${imageData}`,
			);
			if (exif) {
				localStorage.setItem('exif', JSON.stringify(exif, null, 2));
			}

			setIsLoading(false);
			navigate('/pick');
		} catch (error) {
			console.error('Error processing image:', error);
			setIsLoading(false);
		}
	};

	const openCamera = () => {
		camera.open = function () {
			navigator.camera.getPicture(
				function (imageData: string) {
					console.log('Image Data:', imageData);
					handleCameraCapture(imageData);
				},
				function (error: string) {
					console.error('Camera error:', error);
				},
				{
					quality: 50,
					destinationType: Camera.DestinationType.DATA_URL,
				},
			);
		};
		camera.open(); // Modify this line if camera.open is already defined to trigger the function directly.
	};

	useEffect(() => {
		localStorage.removeItem('chromaRgb');
		localStorage.removeItem('imageBlob');
		localStorage.removeItem('blobImage');
		localStorage.removeItem('res-ColorInfo');
		localStorage.removeItem('exif');

		setHeaderOptions((prev) => ({
			...prev,
			showBackward: false,
			showHeader: true,
			template: () => <span className="header-title">HuePick</span>,
		}));

		// 테블릿이나 모바일이 아닌 데스크탑인 경우 페이지 이동
		if (process.env.NODE_ENV === 'production' && isDesktop === true) {
			alert(t('browserModal:run'));
			window.location.href = 'https://huepick.world/';
			return;
		}

		const isModalDismissed = localStorage.getItem('showModal');
		if (!isModalDismissed) {
			setIsUserDisplay(true);
		} else {
			setIsUserDisplay(false);
		}

		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [navigate]);

	useEffect(() => {
		if (!emblaApi) return;
		emblaApi.on('select', () =>
			setSelectedIndex(emblaApi.selectedScrollSnap()),
		);
	}, [emblaApi]);

	const uploadImage = () => {
		if (fileRef.current) {
			fileRef.current?.click();
		}
	};

	// const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	const file = e.target.files ? e.target.files[0] : null;
	// 	setIsLoading(true);
	// 	if (file) {
	// 		try {
	// 			// 이미지 압축 옵션 설정
	// 			const options = {
	// 				maxSizeMB: 2, // 2MB
	// 				// maxWidthOrHeight: 1920, 이거때매 일부 사진 문제 생겼음
	// 			};
	// 			// 이미지 압축과 EXIF 데이터 파싱을 병렬로 처리
	// 			const [compressedFile, exif] = await Promise.all([
	// 				imageCompression(file, options),
	// 				exifr.parse(file),
	// 			]);
	// 			console.log('EXIF Data:', JSON.stringify(exif, null, 2));
	// 			const base64 = await convertImageFileToBase64(compressedFile);
	// 			sessionStorage.setItem('blobImage', base64);
	// 			sessionStorage.setItem('exif', JSON.stringify(exif, null, 2));
	// 			setIsLoading(false);
	// 			navigate('/pick');
	// 		} catch (error) {
	// 			console.error(error);
	// 			setIsLoading(false); // 에러가 발생한 경우에도 로딩 상태를 해제해야 함
	// 		}
	// 	}
	// };

	// const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	const file = e.target.files ? e.target.files[0] : null;
	// 	setIsLoading(true);
	// 	if (file) {
	// 		try {
	// 			// 이미지 압축 옵션 설정
	// 			const options = {
	// 				maxSizeMB: 2,
	// 				// maxWidthOrHeight: 1920,
	// 			};
	// 			const compressedFile = await imageCompression(file, options);

	// 			// 메타데이터 파싱 시도
	// 			let exif;
	// 			try {
	// 				exif = await exifr.parse(file);
	// 			} catch (exifError) {
	// 				console.error('EXIF parsing failed:', exifError);
	// 				exif = null; // EXIF 데이터가 없거나 오류가 발생했을 경우 null로 설정
	// 			}

	// 			console.log(
	// 				'EXIF Data:',
	// 				exif ? JSON.stringify(exif, null, 2) : 'No EXIF data',
	// 			);
	// 			const base64 = await convertImageFileToBase64(compressedFile);
	// 			sessionStorage.setItem('blobImage', base64);
	// 			if (exif) {
	// 				sessionStorage.setItem(
	// 					'exif',
	// 					JSON.stringify(exif, null, 2),
	// 				);
	// 			}
	// 			setIsLoading(false);
	// 			navigate('/pick');
	// 		} catch (error) {
	// 			console.error('General error:', error);
	// 			setIsLoading(false); // 에러가 발생한 경우에도 로딩 상태를 해제해야 함
	// 		}
	// 	}
	// };

	const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		setIsLoading(true);
		if (file) {
			try {
				// 메타데이터 파싱 시도
				let exif;
				try {
					exif = await exifr.parse(file);
				} catch (exifError) {
					console.error('EXIF parsing failed:', exifError);
					exif = null; // EXIF 데이터가 없거나 오류가 발생했을 경우 null로 설정
				}

				console.log(
					'EXIF Data:',
					exif ? JSON.stringify(exif, null, 2) : 'No EXIF data',
				);

				const base64 = await convertImageFileToBase64(file);
				localStorage.setItem('blobImage', base64); // 로컬 스토리지에 이미지 저장
				if (exif) {
					localStorage.setItem('exif', JSON.stringify(exif, null, 2)); // 로컬 스토리지에 EXIF 데이터 저장
				}
				setIsLoading(false);
				navigate('/pick');
			} catch (error) {
				console.error('General error:', error);
				setIsLoading(false); // 에러가 발생한 경우에도 로딩 상태를 해제해야 함
			}
		}
	};

	const handleGalleryImage = async (imageData: string) => {
		setIsLoading(true);
		try {
			const blob = await fetch(
				`data:image/jpeg;base64,${imageData}`,
			).then((res) => res.blob());
			const exif = await exifr.parse(blob);

			console.log(
				'EXIF Data:',
				exif ? JSON.stringify(exif, null, 2) : 'No EXIF data',
			);
			localStorage.setItem(
				'blobImage',
				`data:image/jpeg;base64,${imageData}`,
			);
			if (exif) {
				localStorage.setItem('exif', JSON.stringify(exif, null, 2));
			}

			setIsLoading(false);
			navigate('/pick');
		} catch (error) {
			console.error('Error processing gallery image:', error);
			setIsLoading(false);
		}
	};

	// 갤러리에서 이미지 선택
	const openGallery = () => {
		navigator.camera.getPicture(
			(imageData) => {
				handleGalleryImage(imageData);
			},
			(error) => {
				console.error('Failed to pick image from gallery:', error);
			},
			{
				quality: 50,
				sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,
				destinationType: Camera.DestinationType.DATA_URL,
			},
		);
	};

	const closeBrowserModal = () => {
		setIsUserDisplay(false);
	};

	// 다시 보지 않기 온클릭 이벤트
	const handleDismiss = () => {
		localStorage.setItem('showModal', 'false');
		setIsUserDisplay(false);
	};

	const loadingOverlay = useMemo(() => {
		if (!isLoading) {
			return null; // 아무것도 렌더링하지 않음
		}

		return (
			<div className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-[#7c7b7b5d] flex items-center justify-center">
				<div className="loadingio-spinner-ellipsis-t8pkqbk3ttn">
					<div className="ldio-j17lc9b4yc">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		);
	}, [isLoading]);

	return (
		<>
			{loadingOverlay}
			<div className="upload-bg h-screen flex flex-col justify-center items-center w-[100%]">
				{/* 에러 메시지 표시 */}
				<div className='className="w-full max-w-xs px-6 py-6 flex flex-col items-center bg-[#FFFFFF] shadow-lg rounded-[20px] relative mb-7'>
					<img src={flowerIcon} alt="icon" className="mb-2.5" />
					<span className="text-[16px] font-semibold leading-[26px] text-center mb-5">
						카메라 촬영 또는 사진 갤러리에서 <br />
						좋아하는 컬러를 픽해보세요
					</span>
					<div className="overflow-hidden" ref={emblaRef}>
						<div className="flex">
							{slideImg.map((src, index) => (
								<div
									className="__slide flex flex-col items-center"
									key={index}
								>
									<span className="flex text-center justify-center text-[12px] text-[#666666] leading-[20px]"></span>
									<img
										src={src}
										alt={`Slide ${index + 1}`}
										className="mb-2.5"
									/>
									<span className="text-[14px] font-bold leading-[16.94px] text-[#4430BD] mb-2">
										{slideText[index]}
									</span>
								</div>
							))}
						</div>
						<div className="__buttons-container">
							<div>
								{slideImg.map((_, index) => (
									<div
										key={index}
										className={`__dot${
											index === selectedIndex
												? ' __dot--active'
												: ''
										}`}
										onClick={() =>
											emblaApi && emblaApi.scrollTo(index)
										}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
				<input
					type="file"
					ref={fileRef}
					onChange={handleFile}
					hidden={true}
					accept="image/png, image/jpeg"
				></input>
				<div className="flex items-center">
					<div
						onClick={openCamera}
						className="flex text-center justify-center upload-box"
					>
						<img
							src={cameraIcon}
							alt="ic"
							style={{ width: '30px', height: '30px' }}
							className="mr-2"
						/>
						<h2 className="upload-header">카메라 촬영</h2>
					</div>

					<span className="w-[1px] h-[18px] mx-[20px] opacity-[0.4] bg-[#FFFFFF] "></span>
					<div
						onClick={openGallery}
						className="flex text-center justify-center upload-box"
					>
						<img
							src={galleryIcon}
							alt="ic"
							style={{ width: '30px', height: '30px' }}
							className="mr-2"
						/>
						{/* <h2 className="upload-header">{t('main:upload')}</h2> */}
						{/* 아래 대신 사용하는 부분 */}
						<h2 className="upload-header">촬영 / 갤러리</h2>
					</div>
				</div>
			</div>
			{/* ------------------모달 내용----------------- */}
			{isUserDisplay && (
				<BrowserModal
					onClose={closeBrowserModal}
					handleDismiss={handleDismiss}
				/>
			)}
		</>
	);
}

export default ImageLoader;
